
#react-youtube-channel-container {
  overflow-x: hidden;
  width : 100%;

  .iframe-container {
    float : left;
    width : 100%;
  }

  #outer-video-list-container {
    display : block;
    width : 100%;
    background-color: #494949;
    padding : 0 5px 0 5px;

    .inner-video-list-container {
      overflow-y: auto;
    }
  }

  @media (max-width: 980px) {
    #outer-video-list-container {
      margin-top : -5px;

      .inner-video-list-container {
        overflow-y: auto;
        max-height: 200px;
      }
    }
  }

  .video-container {
    width : 100%;

    img {
      width: 50px;
      height: auto;
      padding-right: 7px;
    }
  }

  .video-container:hover {
    cursor: pointer;
  }

  .title-container,
  .title-container.current {
    width : 100%;
    padding : 5px;
    background-color: black;
    color : #d3d3d3;
    font-family: Verdana, Geneva, sans-serif;
    margin-bottom : 10px;
  }

  .title-container.current {
    background-color: #272727;
  }

  .react-youtube-channel-search-bar-container {
    margin: 5px 0 15px 0;
  }

  @media (min-width: 981px) {
    white-space: nowrap;

    .iframe-container {
      display : inline-block;
      width : 70%;
    }

    #outer-video-list-container {
      float: left;
      width : 30%;
      background-color: #494949;
      padding : 0 5px 0 5px;
    }
  }
}
